import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Branding Project</Text>
                  <Title className="my-4">Skin Script Brand Refresh</Title>
                </Box>
              </Col>
            </Row>
            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/skin-script/brand.png?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    Skin Script is a professional Skin Care company that was
                    established in 2007. They consider themeselves the “latest
                    in skin care thinking”. However, their design is anything
                    but “the latest”. This is a brand idenetity refresh with new
                    logos, colors, pattersn, and typefaces. Skin Script is
                    primarely used tby Estheticians and as an Esthetician myself
                    and a user of these products in a professional setting I
                    felt like I could tackle this brand and bring what is needed
                    for a more up to date style that can be used in every part
                    of their brand from marketing materials to product
                    packaging.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>

          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    Photoshop, Illustrator, InDesign
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className="mt-lg-3 pt-5">
          <Container>
            <Row>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/skin-script/4x/SS-Asset1.png?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/skin-script/4x/SS-Asset2.png?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row className="mb-5">
              <Col xs="12" className="mb-5">
                <img
                  src="/projects/skin-script/products.jpg?nf_resize=fit&w=1140"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="12" className="mb-5">
                <img
                  src="/projects/skin-script/postcard.jpg?nf_resize=fit&w=1140"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/skin-script/bc.jpg?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/skin-script/letterhead.jpg?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/skin-script/bag.jpg?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col lg="6" className="mb-5 pl-lg-4">
                <img
                  src="/projects/skin-script/trade-show.jpg?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section bg="dark" className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/justcbd">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Package Redisign - JustCBD
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
